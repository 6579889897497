import React from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Icon from "../../Icon";
import Rating from "../../Rating";

const Form = ({ className, value, review, handleChange, onSubmit }) => {
  return (
    <form className={cn(styles.form, className)} onSubmit={(e) => onSubmit(e)}>
      <div className={styles.title}>Add a review</div>
      <div className={styles.head}>
        <div className={styles.text}>
          Tell us about your experience interacting with this girl
        </div>
        <Rating
          className={styles.rating}
          initialRating={review}
          setRate={handleChange}
        />
      </div>
      <div className={styles.field}>
        <input
          required
          className={styles.input}
          type="text"
          value={value}
          onChange={handleChange}
          name="text"
          placeholder="Share your thoughts"
          autoComplete="off"
        />
        <button className={cn("button-small", styles.button)}>
          <span>Post it!</span>
          <Icon name="arrow-next" size="14" />
        </button>
      </div>
    </form>
  );
};

export default Form;
