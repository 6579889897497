import React from "react";
import cn from "classnames";
import styles from "./Description.module.sass";
import Details from "./Details";
import Receipt from "../../../components/Receipt";

const items = [
  {
    day: "Day1",
    price: "$0",
  },
  {
    day: "Day2",
    price: "$0",
  },
];

const Description = ({ supports, videos, setting_the_price, classSection }) => {
  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <Details
            videos={videos}
            supports={supports}
            className={styles.details}
          />
          <Receipt
            className={styles.receipt}
            items={setting_the_price.length > 0 ? setting_the_price : items}
            priceOld="$119"
            priceActual="$109"
            time="night"
          ></Receipt>
        </div>
      </div>
    </div>
  );
};

export default Description;
