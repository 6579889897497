import { host } from "../api";

export const getLocations = async () => {
  try {
    const response = await host.get("/v1/location");
    response.data.data.unshift({ id: null, name: "Location" });
    return response.data.data;
  } catch (error) {
    return error;
  }
};
