import React from "react";
import cn from "classnames";
import styles from "./Loader.module.sass";

const Loader = ({ loading, className, color }) => {
  return (
    <div className={loading && styles.loading}>
      <div
        className={cn(styles.loader, className, {
          [styles.loaderWhite]: color === "white",
        })}
      ></div>
    </div>
  );
};

export default Loader;
