import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Footer.module.sass";
import { Link } from "react-router-dom";
import Image from "../Image";
import Icon from "../Icon";
import Theme from "../Theme";
import Form from "../Form";
import Modal from "../Modal";
import ResponseModalContent from "../ResponseModal";
import { createEmail } from "../../api/auth";

const items = [
  {
    title: "Terms of use",
    url: "/terms-of-use",
  },
  {
    title: "Telegram",
    url: "https://t.me/lipseynipsey_manager",
  },
  {
    title: "WhatsApp",
    url: "https://wa.me/447733724545",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [sent, setSent] = useState({
    title: "",
    content: "",
  });
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setVisible(true);
    const postEmail = async () => {
      try {
        const response = await createEmail({ email });
        response?.data?.data &&
          setSent({
            title: "Success",
            content: "Email successfully saved!",
          });
      } catch (error) {
        console.log(error);
        setSent({
          title: "Warning",
          content: "Try it later!",
        });
      }
    };

    postEmail();
  };

  useEffect(() => {
    !visible && setEmail("");
    !visible && setSent({ title: "", content: "" });
  }, [visible]);

  return (
    <div className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/logo-dark.svg"
                srcDark="/images/logo-light.svg"
                alt="Fleet"
              />
            </Link>
            <div className={styles.box}>
              <Icon name="bulb" size="20" />
              <span>Dark theme</span>
              <Theme className={styles.theme} />
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.menu}>
              {items.map((x, index) => (
                <Link className={styles.link} to={x.url} key={index}>
                  {x.title}
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              Join our community{" "}
              <span role="img" aria-label="fire">
                🔥
              </span>
            </div>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={(e) => handleSubmit(e)}
              placeholder="Enter your email"
              type="email"
              name="email"
              icon="arrow-next"
            />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            Copyright © 2024 All rights reserved
          </div>
        </div>
      </div>

      {sent?.title && (
        <Modal
          visible={visible}
          onClose={() => setVisible(false)}
          outerClassName={styles.outer}
        >
          <ResponseModalContent sent={sent} setVisible={setVisible} />
        </Modal>
      )}
    </div>
  );
};

export default Footer;
