import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../Icon";
import ImageHoverSwitcher from "../ImageSliders/ImageHoverSwitcher";
import Options from "./Options";

const Item = ({ className, item }) => {
  return (
    <Link to={`/stays/${item.id}`} className={cn(className, styles.card)}>
      <div className={styles.top}>
        <div className={styles.top_img}>
          <ImageHoverSwitcher images={item.stay_card_images} />
        </div>
        <div className={styles.top__options}>
          <Options cardOption options={item?.options} tags={item?.tags} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.line}>
          <div>
            <div className={styles.title}>{item.name}</div>
            <div className={styles.subtitle}>
              {item?.location?.name || "not selected"}
            </div>
            <div className={styles.flex}>
              <div className={styles.rating}>
                <Icon name="star" size="12" />
                <span className={styles.number}>{item?.ratings_review}</span>
                <span className={styles.review}>
                  ({item?.counter_review} reviews)
                </span>
              </div>
            </div>
          </div>
          <div className={styles.price}>
            <div className={styles.actual}>${item?.first_price}</div>
            <div>{" / "}</div>
            <div className={styles.actual}>${item?.second_price}</div>
          </div>
        </div>

        <div className={styles.btns}>
          <button
            style={{ width: "100%" }}
            className={cn("button-stroke", styles.button)}
          >
            <span>Book a date</span>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default Item;
