import { host } from "../api";

export const getAllComment = async () => {
  try {
    let data = [];
    const response = await host.get("/v1/comment");
    response.data.data.map((res) =>
      data.push({
        id: res.id,
        name: res.name,
        stay_card_images: [
          res.stay_card_image_1.path,
          res.stay_card_image_2.path,
          res.stay_card_image_3.path,
        ],
      })
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const createComment = async (infos) => {
  const response = await host.post("/v1/comment", infos);
  return response;
};
