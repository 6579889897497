import React from "react";
import styles from "./TermsOfUse.module.sass";
import cn from "classnames";

const TermsOfUse = () => {
  return (
    // <div className={styles.footer}>
    <div className={cn("container", styles.termsItems)}>
      {/* -----------HEAD 1----------- */}
      <p className={styles.title}>Terms & Conditions of Lipsey Nipsey Agency </p>
      <p className={styles.title}>Please read all these terms and conditions. These are primarily for clients.</p>
      <p className={styles.title}>1. Acknowledgement</p>
      <div className={styles.content}>
        By accessing, viewing the Lipsey Nipsey Agency pages or arranging to meet
        one of our Escorts you acknowledge that you are 18 years old or older;
        that the services you access, the information you view are not in any way
        illegal/unlawful in your country. That you consent to view material that
        may contain sexual and/or explicit sexual content of adult nature.
        <br />
        There is a search facility on our website where you can view our Models
        by location, type, services, nationality and rates.
      </div>

      {/* -----------HEAD 2 ----------- */}
      <p className={styles.title}>2. Escorts</p>
      <div className={styles.content}>
        All our Escorts are not employed by Lipsey Nipsey Agency. They work
        independently but have chosen to be listed on our website. Adult services
        are matters between you as the client and your chosen Escort.
      </div>

      {/* -----------HEAD 3----------- */}
      <p className={styles.title}>3. Original Photos</p>
      <div className={styles.content}>
        Lipsey Nipsey Agency only uses genuine and actual photos of their Escorts
        and Models. Please understand that when some of our Escorts have their face
        covered, it is so that they will not be recognized. However, in the Members
        area, you might be able to see their faces and uncensored photos.
      </div>

      {/* -----------HEAD 4----------- */}
      <p className={styles.title}>4. Respect</p>
      <div className={styles.content}>
        Our Escorts are often new in this business. They are not professionals and
        do this as a side-line, they either have another job or are studying.
        <br />
        We take care of the psychiatric health of our Escorts by permanently and
        intensively supporting them. However, we kindly ask you, as our honoured
        client, to also take care of our escorts so that in future they will also have
        fun in their part-time job. We expect respectful handling of our Escorts. Only
        this will create perfect harmony. If an Escort declines your request for a service
        she doesn’t offer, it means NO. Please accept that. Should any of our Escorts be
        abused, we will undertake all necessary legal steps. Unpleasant occurrences will be
        registered and sent to the blacklist.
        <br />
        Furthermore, please note that our Escorts do not to give their private numbers and
        refuse to meet you privately without the agency. Therefore, please do not ask!
        By doing that, you don’t do our Escorts a favour.
      </div>

      {/* -----------HEAD 5----------- */}
      <p className={styles.title}>5. Discretion</p>
      <div className={styles.content}>
        Our customers can rely on Lipsey Nipsey Agency for absolute discretion. Any data, which
        is gathered for necessary payments and arrangements will not be filed, electronically
        stored or used in any other way except for the purpose of handling the necessary transactions.
      </div>

      {/* -----------HEAD 6----------- */}
      <p className={styles.title}>6. Booking</p>
      <div className={styles.content}>
        You can arrange an appointment with one of our Escorts and Models by e-mail, by whatsapp, by
        telegram, by text messaging or by means of our online chat facility. Do not hesitate to get
        in touch with us.
        <br />
        For the security of our Escorts, we require: 
        <ul>
          <li>
            your name (no need for a surname in most cases)
          </li>
          <li>
            the exact address of the meeting point (by hotels we also require your room no.)
          </li>
          <li>
            your phone number or if a hotel, the hotel number. So that we can confirm the meeting back.
          </li>
        </ul>
        <br />
        In the case of international travel, we need a pre-payment of the travel expenses (normally the flight cost) plus 50 % of the agreed rate.
      </div>

      {/* -----------HEAD 7----------- */}
      <p className={styles.title}>7. Services</p>
      <div className={styles.content}>
        Lipsey Nipsey Agency’ website provides dating service for adults. Services
        provided by an escort to you are based on lady’s sole discretion. Information in services
        section on escort’s profile page is based on the data provided by escort herself. This is
        mere guidance and not a menu.
      </div>

      {/* -----------HEAD 8----------- */}
      <p className={styles.title}>8. Fees</p>
      <div className={styles.content}>
        Fees advertised for outcalls are for Central London areas only. Travelling fees, including
        taxi charges, apply for most outcalls and discussed with you in any particular case. Incall
        fees do not incur travel costs unless one of the escorts is travelling to the location of
        another escort to meet you.
      </div>

      {/* -----------HEAD 9----------- */}
      <p className={styles.title}>9. Cancellation</p>
      <div className={styles.content}>
        If you have to cancel, please call us in advance. We normally do not charge for cancellation
        for incall appointments. Should escort already be on the way to you (outcall booking) we will
        kindly ask to cover taxi expenses.
        <br />
        Should we have already booked the flight and in the event where we do not get a refund from the
        airline, this amount will be not paid back to you. If an escort is already on her way to you to
        the farther area, another town, city or country we would normally ask to pay for the time she
        spent travelling to you.
        <br />
        In case we have to cancel the booking for whatever reasons and you have made a pre-payment,
        the full amount will be credited to you.
      </div>

      {/* -----------HEAD 10----------- */}
      <p className={styles.title}>10. Payments</p>
      <div className={styles.content}>
        The financial part has to be done within the first 10 minutes of your appointment, directly with the escort.
        <br />
        If you made a pre-payment with international Escort, please pay the missing part of your payment
        directly to our Escort girl at the beginning of your date.
        <br />
        We accept GBP, EUR, USD and CHF. Please ask for our conversion rate.
      </div>

      {/* -----------HEAD 11----------- */}
      <p className={styles.title}>11. Complaints</p>
      <div className={styles.content}>
        In the unlikely event of you being unhappy with your chosen Escort or her service, please contact
        us immediately so that we can intervene. After the appointment, we are not able to react, however,
        any complaints are taken very seriously and it helps us improve our outstanding service.
      </div>

      {/* -----------HEAD 12----------- */}
      <p className={styles.title}>12. Suggestions</p>
      <div className={styles.content}>
        We aim to please and would welcome any feedback or suggestion. Please do not hesitate to contact
        us by any method provided on the website
      </div>

      {/* -----------HEAD 13----------- */}
      <p className={styles.title}>13. Guidelines that we use and organisations we support</p>
      <div className={styles.content}>
        We are constantly monitoring the UKNEA UKNEA logo for industry-wide updates. We are also supporting
        Ugly Mugs CharityUgly Mugs Charity logo
      </div>

      {/* -----------HEAD 14----------- */}
      <div className={styles.content}>
        reviewed 25/02/2024
      </div>
    </div>
    // </div>
  );
};

export default TermsOfUse;
