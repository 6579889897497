import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Comment.module.sass";
import Form from "./Form";
import List from "./List";
import Modal from "../Modal";
import ReviewUserForm from "../ReviewUserForm";
import { createComment } from "../../api/comments";
import { dateFormatterIso } from "../../helpers/dateFormatter";
import ResponseModalContent from "../ResponseModal";

const Comment = ({ className, comments }) => {
  const stay_id = useParams();

  // STATES
  const [visible, setVisible] = useState(false);
  const [sent, setSent] = useState({
    status: false,
    title: "Submit a review",
    content: "We will vet your review before publishing.",
  });
  const [dateWet, setDateWet] = useState(false);
  const [comment, setComment] = useState({
    review: "4",
    text: "",
    date: null,
    user_name: "",
    user_email: "",
  });

  useEffect(() => {
    setSent({
      status: false,
      title: "Submit a review",
      content: "We will vet your review before publishing.",
    });
  }, [visible]);

  // handleChange for change states
  const handleChange = (e, name = false) => {
    !name
      ? setComment({ ...comment, [e.target.name]: e.target.value })
      : setComment({ ...comment, [name]: e });
  };

  const handleSubmitPostIt = (e) => {
    e.preventDefault();
    setVisible(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (comment.date) {
      const postComment = async () => {
        try {
          const response = await createComment({
            stay_id: Number(stay_id.id),
            user_name: comment.user_name,
            user_email: comment.user_email,
            comment_time: dateFormatterIso(comment.date),
            review: Number(comment.review),
            status: true,
            text: comment.text,
          });
          response.status === 201 &&
            setSent({
              status: true,
              title: "Review sent",
              content:
                "The feedback will be published after the order is confirmed by moderation",
            });
        } catch (error) {
          console.log(error);
          setSent({
            status: true,
            title: "Review Warning",
            content: "The feedback cannot sent at this time.",
          });
        }
      };
      postComment();
    } else {
      setDateWet(true);
    }
  };

  return (
    <div className={cn(className, styles.section)}>
      <Form
        className={styles.form}
        value={comment.text}
        review={comment.review}
        onSubmit={handleSubmitPostIt}
        handleChange={handleChange}
      />
      {comments?.length > 0 ? <List items={comments} /> : <p>No commets yet</p>}
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        outerClassName={styles.outer}
      >
        {!sent.status ? (
          <ReviewUserForm
            sent={sent}
            date={comment.date}
            user_name={comment.user_name}
            user_email={comment.user_email}
            setState={handleChange}
            onSubmit={handleSubmit}
            dateWet={dateWet}
          />
        ) : (
          <ResponseModalContent sent={sent} setVisible={setVisible} />
        )}
      </Modal>
    </div>
  );
};

export default Comment;
