import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../../components/Icon";
import VideoPlayers from "../../../../components/VidePlayers";

const Details = ({ videos, supports, className }) => {
  return (
    <div className={cn(className, styles.details)}>
      <div className={styles.display_desktop}>
        <p className={styles.info}>Video</p>
        <div style={{ display: "flex", gap: "12px" }}>
          <VideoPlayers sources={videos} />
        </div>
      </div>
      {supports.length > 0 && supports[0]?.text && supports[0]?.price && (
        <>
          <p className={styles.info}>Services</p>
          <div className={styles.options}>
            {supports.map((support, index) =>
              support.status === "1" ? (
                <div className={styles.option} key={index}>
                  <Icon name="tick" className={styles.fill_green} size="24" />
                  <span className={cn(styles.title, styles.color_green)}>
                    {support.text}
                  </span>
                  <span className={styles.color_green}>{support.price}</span>
                </div>
              ) : (
                <div className={styles.option} key={index}>
                  <Icon name="close" className={styles.fill_gray} size="24" />
                  <span className={cn(styles.title, styles.color_gray)}>
                    {support.text}
                  </span>
                  <span className={styles.color_gray}>{support.price}</span>
                </div>
              )
            )}
            {/* {Object.entries(staySupports).map(([key, value]) =>
          supports.includes(`${key}`) ? (
            <div className={styles.option} key={key}>
              <Icon name="tick" className={styles.fill_green} size="24" />
              <span className={cn(styles.title, styles.color_green)}>
                {value}
              </span>
              <span className={styles.color_gray}>$10</span>
            </div>
          ) : (
            <div className={styles.option} key={key}>
              <Icon name="close" className={styles.fill_gray} size="24" />
              <span className={cn(styles.title, styles.color_gray)}>
                {value}
              </span>
            </div>
          )
        )} */}
          </div>
        </>
      )}
    </div>
  );
};

export default Details;
