import React, { useState } from "react";
import cn from "classnames";
import styles from "./List.module.sass";
import Rating from "../../Rating";
import Dropdown from "../../Dropdown";
import Loader from "../../Loader";
import loadMore from "../../../helpers/laodMore";

const dateOptions = [{ name: "Newest" }, { name: "Popular" }];

const List = ({ items }) => {
  const [sort, setSort] = useState(dateOptions[0]);
  // const [reversed] = useState(Array.from(items).reverse());
  const [visibleComments, setVisibleComments] = useState(9);

  return (
    <div className={styles.list}>
      <div className={styles.head}>
        <div className={styles.title}>{items.length} comments</div>
        <Dropdown
          className={styles.dropdown}
          value={sort}
          setValue={setSort}
          options={dateOptions}
        />
      </div>
      <div className={styles.group}>
        {items.slice(0, visibleComments).map((x, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.details}>
              <div className={styles.top}>
                <div className={styles.author}>{x.user_name}</div>
                <Rating
                  className={styles.rating}
                  readonly
                  initialRating={x.review}
                />
              </div>
              <div className={styles.content}>{x.text}</div>
              <div className={styles.foot}>
                <div className={styles.time}>{x.comment_time}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {visibleComments < items.length && (
        <div className={styles.btns}>
          <button
            onClick={() => loadMore(setVisibleComments)}
            className={cn("button-stroke", styles.button)}
          >
            <Loader className={styles.loader} />
            <span>Show more</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default List;
