import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Options.module.sass";
import Modal from "../../Modal";
import Login from "../../Login";
import { createEmail } from "../../../api/auth";
import ResponseModalContent from "../../ResponseModal";

const Options = ({ options, tags, cardOption = false }) => {
  const [visible, setVisible] = useState(false);
  const [sent, setSent] = useState({
    status: false,
    title: "Communicate",
    content: "Write and mention the girl's name in the message",
  });
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const postEmail = async () => {
      try {
        await createEmail({ email });
        setSent({
          status: true,
          title: "Success",
          content: "Email successfully saved!",
        });
      } catch (error) {
        console.log(error);
        setSent({
          status: true,
          title: "Warning",
          content: "Try it later!",
        });
      }
    };

    postEmail();
  };

  useEffect(() => {
    setSent({
      status: false,
      title: "Communicate",
      content: "Write and mention the girl's name in the message",
    });
  }, [visible]);

  const handleButton = () => {
    setVisible(!visible);
  };
  return (
    <>
      {options?.map((x, index) => (
        <div className={styles.option} key={index}>
          <p
            className={styles.label}
            style={{ width: cardOption ? "50%" : "140px" }}
          >
            {x.key} :
          </p>
          <p
            className={styles.title}
            style={{ width: cardOption ? "50%" : "max-content" }}
          >
            {x.value}
          </p>
        </div>
      ))}
      <div className={styles.tags}>
        {tags?.length
          ? tags.map((tag, index) => (
              <p key={index} className={styles.tag}>
                {tag.tag}
              </p>
            ))
          : ""}
      </div>
      {!cardOption && (
        <div className={styles.button_details}>
          <button
            className={cn("button-blue", styles.button)}
            onClick={() => handleButton()}
          >
            <span>Book a date</span>
          </button>
        </div>
      )}

      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        outerClassName={styles.outer}
      >
        {!sent.status ? (
          <Login
            sent={sent}
            email={email}
            setEmail={setEmail}
            handleSubmit={handleSubmit}
          />
        ) : (
          <ResponseModalContent sent={sent} setVisible={setVisible} />
        )}
      </Modal>
    </>
  );
};

export default Options;
