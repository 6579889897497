import React from "react";
import cn from "classnames";
import styles from "./CommentsProduct.module.sass";
import Comment from "../Comment";

const CommentsProduct = ({ className, comments }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <Comment comments={comments} />
        </div>
      </div>
    </div>
  );
};

export default CommentsProduct;
