import React, { useState } from "react";
import styles from "./Switcher.module.sass";

const ImageHoverSwitcher = ({ images }) => {
  const [hoveredArea, setHoveredArea] = useState(null);

  const handleMouseEnter = (area) => {
    setHoveredArea(area);
  };

  const handleMouseLeave = () => {
    setHoveredArea(null);
  };

  const getImageToShow = () => {
    switch (hoveredArea) {
      case "left":
        return images[0];
      case "center":
        return images[1];
      case "right":
        return images[2];
      default:
        return images[0];
    }
  };

  return (
    <>
      <div className={styles.card__img_slider}>
        <div
          className={styles.img_container}
          style={{
            backgroundImage: `url(${getImageToShow()})`,
          }}
        >
          <div
            className={styles.card_image}
            onMouseEnter={() => handleMouseEnter("left")}
            onMouseLeave={handleMouseLeave}
          ></div>
          <div
            className={styles.card_image}
            onMouseEnter={() => handleMouseEnter("center")}
            onMouseLeave={handleMouseLeave}
          ></div>
          <div
            className={styles.card_image}
            onMouseEnter={() => handleMouseEnter("right")}
            onMouseLeave={handleMouseLeave}
          ></div>
        </div>
      </div>
      <ul className={styles.dots}>
        <li
          style={{
            backgroundColor:
              hoveredArea === "left" || !hoveredArea ? "#3B71FE" : "#B1B5C3",
          }}
        ></li>
        <li
          style={{
            backgroundColor: hoveredArea === "center" ? "#3B71FE" : "#B1B5C3",
          }}
        ></li>
        <li
          style={{
            backgroundColor: hoveredArea === "right" ? "#3B71FE" : "#B1B5C3",
          }}
        ></li>
      </ul>
    </>
  );
};

export default ImageHoverSwitcher;
