import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Sorting from "../../../components/Sorting";
import Browse from "../../../components/Browse";
import Card from "../../../components/Card";
import Loader from "../../../components/Loader";

// http
import { getAllStays, getBrowseStays } from "../../../api/stays";
import { getLocations } from "../../../api/locations";
import loadMore from "../../../helpers/laodMore";

const navigations = ["All", "New"];

const Catalog = () => {
  const [navigation, setNavigation] = useState("All");
  const [filterLocations, setFilterLocations] = useState();
  const [location, setLocation] = useState({ id: null, name: "Location" });

  const [stays, setStays] = useState({ data: [], names: [] });
  const [browseStays, setBrowseStays] = useState([]);
  const [render, setRender] = useState("loading");

  // showMore and visibleItems
  const [visibleItems, setVisibleItems] = useState(9);

  // getBrowseStays
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBrowseStays();
        setBrowseStays(response);
      } catch (error) {
        setRender("error");
      }
    };
    fetchData();
  }, []);

  // GetAllStays && GetLocations
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllStays({ news: navigation, location });
        setStays(response);
        const resLocations = await getLocations();
        setFilterLocations(resLocations);
        setRender(false);
      } catch (error) {
        setRender("error");
      }
    };
    fetchData();
  }, [location, navigation]);

  if (render === "loading") return <Loader loading className={styles.loader} />;
  else if (render === "error") return <p>Some error occured</p>;
  else {
    return (
      <div className={cn("section", styles.section)}>
        <Browse
          classSection="section-my80"
          headSmall
          classTitle="h2"
          title="Recommended escort girls"
          info="Here are our best girls"
          items={browseStays}
        />
        <div className={styles.body}>
          <div className={cn("container", styles.container)}>
            <h4 className={cn("h4", styles.title)}>London escorts</h4>
            <Sorting
              className={styles.sorting}
              urlHome="/"
              navigations={navigations}
              setNavigation={setNavigation}
              sorting={location}
              setSorting={setLocation}
              sortingOptions={filterLocations}
              sortStayNames={stays.stayNames}
            />
            <div className={styles.list}>
              {stays?.data.slice(0, visibleItems).map((stay, index) => (
                <Card className={styles.card} item={stay} key={stay.id} />
              ))}
            </div>
            {visibleItems < stays.data.length && (
              <div className={styles.btns}>
                <button
                  onClick={() => loadMore(setVisibleItems)}
                  className={cn("button-stroke", styles.button)}
                >
                  <Loader className={styles.loader} />
                  <span>Show more</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Catalog;
