import { host } from "../api";

export const getAllStays = async ({ news, location }) => {
  news = news === "All" ? 0 : 1;
  location = location.id;
  let mparams =
    news !== 0 && location != null
      ? { new: news, location }
      : news === 1 && location == null
      ? { new: news }
      : news === 0 && location != null
      ? { location }
      : {};
  try {
    let data = [],
      stayNames = [];
    const response = await host.get("/v1/stays", {
      params: mparams,
    });
    response?.data?.data &&
      response.data.data.map(
        (res) => (
          stayNames.push({ id: res.id, name: res.name }),
          data.push({
            id: res.id,
            name: res.name,
            ratings_review: res.ratings_review,
            counter_review: res.counter_review,
            options: [
              {
                key: "Age",
                value: res?.guests || "0",
              },
              {
                key: "Breast",
                value: res?.bath || "0",
              },
              {
                key: "Height",
                value: res?.room || "0",
              },
              {
                key: "Weight",
                value: res?.space || "Space",
              },
            ],
            location: res?.location || "null",
            tags: res?.tags_on_card || [],
            first_price: res?.first_price || "0",
            second_price: res?.second_price || "0",
            stay_card_images: [
              res.stay_card_image_1.path,
              res.stay_card_image_2.path,
              res.stay_card_image_3.path,
            ],
          })
        )
      );

    return { data, stayNames };
  } catch (error) {
    return error;
  }
};

export const getBrowseStays = async () => {
  try {
    let data = [];
    const response = await host.get("/v1/stays", {
      params: { browse: 1 },
    });
    (response.data.data.length > 4
      ? response.data.data.splice(-4)
      : response.data.data
    ).map((res) =>
      data.push({
        id: res.id,
        name: res.name,
        ratings_review: res.ratings_review,
        counter_review: res.counter_review,
        options: [
          {
            key: "Age",
            value: res?.guests || "0",
          },
          {
            key: "Breast",
            value: res?.bath || "0",
          },
          {
            key: "Height",
            value: res?.room || "0",
          },
          {
            key: "Weight",
            value: res?.space || "Space",
          },
        ],
        location: res?.location || "null",
        tags: res?.tags_on_card || [],
        first_price: res?.first_price || "0",
        second_price: res?.second_price || "0",
        stay_card_images: [
          res.stay_card_image_1.path,
          res.stay_card_image_2.path,
          res.stay_card_image_3.path,
        ],
      })
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getOneStay = async (id) => {
  try {
    let data = [];
    const response = await host.get(`/v1/stays/${id}`);
    data = {
      id: response.data.data[0][0].id,
      name: response.data.data[0][0].name,
      ratings_review: response.data.data[0][0].ratings_review,
      counter_review: response.data.data[0][0].counter_review,
      location: response.data.data[0][0]?.location || "null",
      options: [
        {
          key: "Age",
          value: response.data.data[0][0]?.guests || "null",
        },
        {
          key: "Breast size",
          value: response.data.data[0][0]?.bath || "null",
        },
        {
          key: "Height",
          value: response.data.data[0][0]?.room || "null",
        },
        {
          key: "Weight",
          value: response.data.data[0][0]?.space || "null",
        },
        {
          key: "Dress size;",
          value: response.data.data[0][0]?.wifi || "null",
        },
        {
          key: "Hair colour",
          value: response.data.data[0][0]?.tv || "null",
        },
        {
          key: "Eye colour",
          value: response.data.data[0][0]?.air_conditioning || "null",
        },
        {
          key: "Orientation",
          value: response.data.data[0][0]?.kitchen || "null",
        },
        {
          key: "Smoking",
          value: response.data.data[0][0]?.refrigerator || "null",
        },
        {
          key: "Tattoo",
          value: response.data.data[0][0]?.balcony || "null",
        },
        {
          key: "Nationality",
          value: response.data.data[0][0]?.self_check_in || "null",
        },
        {
          key: "Language",
          value: response.data.data[0][0]?.elevator || "null",
        },
      ],
      setting_the_price: response.data.data[0][0]?.setting_the_price || [],
      tags: response.data.data[0][0]?.tags_on_page || [],
      support: response.data.data[0][0]?.support || [],
      image_collections: response.data.data[0][0].image_collections.map(
        (image) => image.path
      ),
      video_collections: response.data.data[0][0].video_collections.map(
        (video) => video.path
      ),
      comments: response.data.data[0][0].comments,
    };
    return data;
  } catch (error) {
    return error;
  }
};
