import { useState } from "react";
import "./ImageThumbSlider.sass";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import "swiper/swiper-bundle.css";

const ImageThumbSlider = ({ images }) => {
  const [activeThumb, setActiveThumb] = useState(null);
  return (
    <>
      <Swiper
        loop={false}
        spaceBetween={10}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        modules={[Navigation, Thumbs]}
        grabCursor={false}
        thumbs={{
          swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null,
        }}
        className="product-images-slide"
        effect="slide"
      >
        {images.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item} alt="product_images" />
          </SwiperSlide>
        ))}
        <img
          className="swiper-button-prev"
          src="/icons/arrow_left.svg"
          alt="arrow-left-prev-icon"
        />
        <img
          className="swiper-button-next"
          src="/icons/arrow_right.svg"
          alt="arrow-right-next-icon"
        />
      </Swiper>
      <Swiper
        onSwiper={setActiveThumb}
        loop={false}
        spaceBetween={7}
        slidesPerView={9}
        breakpoints={{
          320: {
            slidesPerView: 4.5,
          },
          639: {
            slidesPerView: 5.5,
          },
          767: {
            slidesPerView: 8.5,
          },
        }}
        modules={[Navigation, Thumbs]}
        className="product-images-slider-thumbs"
        style={{ width: "auto" }}
      >
        {images.map((item, index) => (
          <SwiperSlide key={index} style={{ width: "auto" }}>
            <div className="product-images-slider-thumbs-wrapper">
              <img src={`${item}`} alt="product_images" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

ImageThumbSlider.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ImageThumbSlider;
