import React from "react";
import cn from "classnames";
import styles from "./Receipt.module.sass";
import Icon from "../Icon";

const Receipt = ({ className, items, children }) => {
  return (
    <div className={cn(className, styles.receipt)}>
      <h3 className={styles.label}>Rates</h3>

      {items.map(
        (x, index) =>
          items[index * 2] && (
            <div className={cn(styles.description, styles.flex)} key={index}>
              {items[index * 2] && (
                <div className={styles.item}>
                  <div className={styles.icon}>
                    <Icon name="clock" size="24" />
                  </div>
                  <div className={styles.box}>
                    <div className={styles.category}>
                      {items[index * 2].day}
                    </div>
                    <div className={styles.subtitle}>
                      {items[index * 2].price}
                    </div>
                  </div>
                </div>
              )}
              {items[index * 2 + 1] && (
                <div className={styles.item}>
                  <div className={styles.icon}>
                    <Icon name="clock" size="24" />
                  </div>
                  <div className={styles.box}>
                    <div className={styles.category}>
                      {items[index * 2 + 1].day}
                    </div>
                    <div className={styles.subtitle}>
                      {items[index * 2 + 1].price}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
      )}
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default Receipt;
