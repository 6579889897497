import React from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";

const Header = ({ separatorHeader, wide, notAuthorized }) => {
  return (
    <>
      <div
        className={cn(
          styles.header,
          { [styles.headerBorder]: separatorHeader },
          { [styles.wide]: wide }
        )}
      >
        <div className={cn("container", styles.container)}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              src="/images/logo-dark.svg"
              srcDark="/images/logo-light.svg"
              alt="Fleet"
            />
          </Link>
          <div className={cn(styles.wrapper)}>
            <div className={cn(styles.seven_days, styles.mr_24)}>
              7 days a week
            </div>
            <NavLink className={styles.mr_24} to="https://t.me/lipseynipsey_manager">
              <img
                src="/icons/telegram.svg"
                className={styles.social_icons}
                alt="telegram-icon"
              />
              <span className={styles.link}>Telegram</span>
            </NavLink>
            <NavLink className={styles.mr_24} to="https://wa.me/447733724545">
              <img
                src="/icons/whatsapp.svg"
                className={styles.social_icons}
                alt="whatsapp-icon"
              />
              <span className={styles.link}>WhatsApp</span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
