import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import cn from "classnames";
import styles from "./StaysProduct.module.sass";
import Product from "../../components/Product";
import Description from "./Description";
import CommentsProduct from "../../components/CommentsProduct";
import Browse from "../../components/Browse";

// data
import { getBrowseStays, getOneStay } from "../../api/stays";
import Loader from "../../components/Loader";

const StaysProduct = () => {
  const { id } = useParams();
  const breadcrumbs = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "stays-product",
      url: `/stays/${id}`,
    },
  ];

  const [browseStays, setBrowseStays] = useState([]);
  const [stay, setStay] = useState(null);
  const [loading, setLoading] = useState("loading");

  useEffect(() => {
    const fetchBrowse = async () => {
      try {
        const resBrowse = await getBrowseStays();
        setBrowseStays(resBrowse);
        const resStayDetail = await getOneStay(id);
        setStay(resStayDetail);
        setLoading("allRight");
      } catch (error) {
        setLoading("error");
      }
    };
    fetchBrowse();
  }, [id]);

  if (loading === "loading") {
    return <Loader loading className={styles.loader} />;
  } else if (loading === "error") {
    return <p>Some Error...</p>;
  } else {
    return (
      <>
        <Product
          classSection="section-mb64"
          urlHome="/"
          stayInfos={stay}
          breadcrumbs={breadcrumbs}
        />
        <Description
          videos={stay?.video_collections}
          supports={stay?.support}
          setting_the_price={stay?.setting_the_price}
          classSection="section"
        />
        <CommentsProduct
          comments={stay?.comments?.length ? stay.comments : []}
          className={cn("section", styles.comment)}
        />
        <Browse
          classSection="section-my80"
          headSmall
          classTitle="h2"
          title="Recommended escort girls"
          info="Here are our best girls"
          items={browseStays}
        />
      </>
    );
  }
};

export default StaysProduct;
