import axios from "axios";

const host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    // Add other headers as needed
  },
});

export { host };
