import React, { useState } from "react";
import cn from "classnames";
import styles from "./Sorting.module.sass";
import Dropdown from "../Dropdown";

const Sorting = ({
  className,
  navigations,
  setNavigation,
  sorting,
  setSorting,
  sortingOptions,
  sortStayNames,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleNavigation = (x, index) => {
    setActiveIndex(index);
    setNavigation(x);
  };
  return (
    <div className={cn(className, styles.sorting)}>
      <div className={styles.foot}>
        <div className={styles.nav}>
          {navigations.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => handleNavigation(x, index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <Dropdown
          linked
          className={cn(styles.dropdown, "mr10")}
          options={sortStayNames}
        />
        <Dropdown
          className={styles.dropdown}
          value={sorting}
          setValue={setSorting}
          options={sortingOptions}
        />
      </div>
    </div>
  );
};

export default Sorting;
