import React from "react";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import styles from "./VideoPlayers.module.sass";

const VideoPlayers = ({ sources }) => {
  return (
    <div className={styles.players}>
      <style>
        {`
          .plyr {
            min-width: auto;
          }
          .plyr__poster{
            background-color: rgba(255, 255, 255, 0)
          }
        `}
      </style>
      {sources?.map((source, index) => (
        <Plyr
          key={index}
          options={{
            controls: ["play", "progress", "fullscreen"],
            previewThumbnails: {
              enabled: false,
              src: "",
            },
          }}
          source={{
            type: "video",
            // title: "Example title",
            sources: [
              {
                src: source,
                type: "video/mp4",
                // size: 720,
              },
            ],
          }}
          // poster: "/images/content/card-pic-1.jpg",
        />
      ))}
    </div>
  );
};

export default VideoPlayers;
