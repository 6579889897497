import React from "react";
import cn from "classnames";
import styles from "./Login.module.sass";
import Form from "../Form";

const Login = ({ sent, email, setEmail, handleSubmit }) => {
  return (
    <div className={cn(styles.login)}>
      <div className={styles.item}>
        <div className={cn("h3", styles.title)}>{sent.title}</div>
        <div className={styles.info}>{sent.content}</div>
        <div className={styles.btns}>
          <button className={cn("button", styles.button)}>
            <img
              src="/icons/whatsapp.svg"
              alt="tgIcom"
              className={styles.iconStyle}
            />
            <span className="social">WhatsApp</span>
          </button>
          <button className={cn("button", styles.button)}>
            <img
              src="/icons/telegram.svg"
              alt="tgIcom"
              className={styles.iconStyle}
            />
            <span className="social">Telegram</span>
          </button>
        </div>
        <div className={styles.note}>Or continue with email</div>
        <Form
          className={styles.form}
          handleSubmit
          value={email}
          setValue={setEmail}
          onSubmit={(e) => handleSubmit(e)}
          placeholder="Enter your email"
          type="email"
          name="email"
          icon="arrow-next"
        />
      </div>
    </div>
  );
};

export default Login;
