import React from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "../Control";
import Icon from "../Icon";
import Actions from "../Actions";

import Options from "../Card/Options";
import ImageThumbSlider from "../ImageSliders/ImageThumbSlider";
import VideoPlayers from "../VidePlayers";

const Product = ({ classSection, urlHome, breadcrumbs, stayInfos }) => {
  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <Control
          className={styles.control}
          urlHome={urlHome}
          breadcrumbs={breadcrumbs}
        />
        <div className={styles.head}>
          <div className={styles.box}>
            <h1 className={cn("h2", styles.title)}>{stayInfos?.name}</h1>
            <div className={styles.line}>
              <div className={styles.rating}>
                <Icon name="star" size="20" />
                <div className={styles.number}>{stayInfos.ratings_review}</div>
                <div className={styles.reviews}>
                  ({stayInfos.counter_review} reviews)
                </div>
              </div>
              <div className={styles.options}>
                <div className={styles.option}>
                  <Icon name="flag" size="20" />
                  {stayInfos?.location?.name}
                </div>
              </div>
            </div>
          </div>
          <Actions className={styles.actions} />
        </div>

        <div className={styles.top}>
          <div className={styles.top_img}>
            <ImageThumbSlider
              images={
                stayInfos?.image_collections?.length
                  ? stayInfos.image_collections
                  : []
              }
            />
          </div>
          <div className={styles.display_mb}>
            <div className={styles.info}>Video</div>
            <div style={{ display: "flex", gap: "12px" }}>
              <VideoPlayers
                sources={
                  stayInfos?.video_collections?.length > 0
                    ? stayInfos.video_collections
                    : []
                }
              />
            </div>
          </div>
          <div className={styles.top__options}>
            <div className={styles.info}>Services</div>
            <Options options={stayInfos?.options} tags={stayInfos?.tags} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
