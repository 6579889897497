import React from "react";
import cn from "classnames";
import styles from "./ReviewUserForm.module.sass";

const ResponseModalContent = ({ setVisible, sent }) => {
  return (
    <div className={styles.item}>
      <div className={cn("h3", styles.title)}>{sent.title}</div>
      <div className={styles.info}>{sent.content}</div>
      <div className={styles.btns}>
        <button
          className={cn("button", styles.button)}
          type="button"
          onClick={() => setVisible(false)}
        >
          <span>Continue</span>
        </button>
      </div>
    </div>
  );
};

export default ResponseModalContent;
