import React from "react";

import cn from "classnames";
import styles from "./ReviewUserForm.module.sass";
import TextInput from "../TextInput";
import DateSingle from "../DateSingle";

const ReviewUserForm = ({
  date,
  user_name,
  user_email,
  setState,
  onSubmit,
  dateWet,
  sent,
}) => {
  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div className={styles.item}>
        <div className={cn("h3", styles.title)}>{sent.title}</div>
        <div className={styles.info}>{sent.content}</div>
        {!sent.status && (
          <>
            <DateSingle
              selectedDate={date}
              setSelectedDate={setState}
              required
              small
              className={styles.date}
              icon="edit"
              description="Add date"
              placeholder="Date"
              displayFormat="DD MMM, YYYY"
            />
            {dateWet && (
              <small style={{ color: "red" }}>Date is required</small>
            )}
            <TextInput
              className={styles.field}
              label="YOUR NAME"
              type="text"
              placeholder="TRAN MAU TRI TAM"
              required
              name="user_name"
              value={user_name}
              onChange={setState}
              autoComplete="off"
            />
            <TextInput
              className={styles.field}
              label="YOUR EMAIL"
              type="text"
              placeholder="TRAN MAU TRI TAM"
              required
              name="user_email"
              value={user_email}
              onChange={setState}
              autoComplete="off"
            />
          </>
        )}
        <div className={styles.btns}>
          <button className={cn("button", styles.button)} type="submit">
            <span>Post review</span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default ReviewUserForm;
